<template>
  <div id="app-layout">
       <div class="home__title">
              <h2 class="home__title__bg">ACTUALITÉS</h2>
            </div>         
            <div class="pagecontent">
              <div id="letters">
                  <div id="error">
                  <i class="fas fa-exclamation-triangle"></i> Une erreur est survenue, merci de réessayer plus tard.
                 </div>
                 <div class="letters__one" v-for="item in items" :key="item.link">
                   <a :href="item.link" class="letters__link">
                      <h3 class="letters__title">{{item.title}}</h3>
                      <div class="actu">
                          <img class="actu__img" :src="item.enclosure.url" :alt="item.title">
                          <div class="letters__text" v-html="item.content"></div>
                      </div>
                      <p class="letters__date">{{dateLocale(item.pubDate)}}</p>
                    </a>       
                  </div>
            </div> 
       </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Actu",
    data(){
        return{
            items: [],            
        }
    },
    mounted(){
        this.getAllItems();
    },
    methods: {
        getAllItems(){
        axios.get(`https://wt-c2bde7d7dfc8623f121b0eb5a7102930-0.sandbox.auth0-extend.com/getRss?url=https://www.notaires.fr/fr/rss/actualites`, // 
                {
                    headers: { // headers de la requete dont le token d'authentification
                        'Content-Type': 'application/json',
                    }
                }
            )
            .then(res => {
                        if(res.status === 200) {
                            let error = document.getElementById("error"); // Récupère la div contenant le message d'erreur
                            error.remove();
                            const feed = res.data;
                            console.log("Connecté");
                            this.items = feed.feed.items;
                        }
            });
        },
        dateLocale(date){ // fonction de formatage de la date
            const dateFormat = new Date(date); // formatage en Date
            const options = { year: 'numeric', month: 'long', day: 'numeric'}; // Option du formatage
            return dateFormat.toLocaleDateString('fr-FR', options); // Retour de la date formatée
        }
    }
}
         
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

 .letters {
     &__one {
         border: $tertiary-color 1px solid;
         box-sizing: border-box;
         padding: 5px 20px;
         margin-bottom: 15px;
         border-radius: 3px;
     }
     &__text {
        max-height: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        @media screen and (max-width: 750px){
          flex-direction: column;
          max-height: none;
          text-align: justify;
        }
     }
     &__date {
        text-align: end;
     }
     &__title {
       margin: 15px 0;
     }
 }

 .field-type-image {
     margin-right: 20px;
      @media screen and (max-width: 750px){
        margin: auto;
      }
 }

 .field-name-field-lnf-insert-1-text, .field-name-field-lnf-insert-2-cover, .field-name-field-lnf-insert-2-text, .field-name-field-lnf-front-body-text {
   display: none;
 }

 .actu {
   display: flex;
   align-items: center;
   @media screen and (max-width: 750px){
        flex-direction: column;
      }
   &__img {
     width: 35%;
    margin-right: 15px;
    @media screen and (max-width: 750px){
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
      }
   }
 }

</style>